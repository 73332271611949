@import '../../scss/config/_colors.scss';
@import '../../scss/config/_materials.scss';
@import '../../scss/config/_typography.scss';
@import '../../scss/elements/_tooltip.scss';

$c-sidebar: darken($c-background, 0%);
$c-sidebar: $c-white;

.side-panel {
  z-index: 4;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: $header-height + 1;
  bottom: 0;
  border-left: 1px solid $c-grey-light;
  background-color: $c-sidebar;
  width: calc(100% - #{$header-height});
  transition: right 300ms;

  &.is-hidden {
    right: -100%;
  }

  @media (max-width: 1023px) {
    z-index: 6;
  }

  @media (min-width: 454px) {
    width: 400px;

    &.is-hidden {
      right: -400px;
    }
  }

  &.two-columns #metadataForm {
    display: none;
  }

  @media (min-width: 700px) {
    &.two-columns {
      width: 700px;
      box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.5);
    }

    &.two-columns #metadataForm {
      display: block;
      min-width: 350px;
    }
  }

  &.two-columns .sidepanel-body .tab-content.tab-content-visible {
    display: flex;

    > div {
      border-left: 1px solid $c-grey-light;
    }
  }

  &.wide {
    min-width: 901px;

    &.is-hidden {
      right: -901px;
    }
  }

  .errormsg {
    margin: 20px;
    font-size: 15px;
  }

  h1 {
    font-size: $f-size-lg;
  }

  .item-icon.item-icon-center {
    & img {
      vertical-align: bottom;
    }

    & i.fa {
      line-height: 0;
      margin: 0;
    }
  }

  .item-info .btn-color {
    display: inline-block;
    margin-top: 8px;
    font-size: $f-size-sm;
  }

  .closeSidepanel {
    position: absolute;
    top: 0;
    right: 0;
    width: $header-height;
    height: $header-height;
    line-height: $header-height;
    text-align: center;
    font-size: $f-size-lg;
    color: $c-grey;
    cursor: pointer;
    z-index: 5;
    border: 0;
    background-color: transparent;

    &:hover {
      color: $c-grey-dark;
    }
  }

  .form-group {
    margin: 0;

    .fa-warning {
      color: $c-warning;
    }
  }

  .sort-by {
    padding: 5px 15px;
    height: 30px;
    margin: 0;

    .Dropdown {
      right: 15px;
    }
  }

  .item-group-header {
    display: block;
    width: 100%;
    padding: 6px 15px;
    text-align: left;
    border: 0;
    background: 0;
    box-shadow: none;
    outline: none;
    background: $c-grey-lighten;
    margin-bottom: 1px;

    &.is-expanded {
      border-bottom: 1px solid $c-grey-light;
      color: $c-primary;
    }

    .itemGroup-title {
      font-weight: bold;
    }

    &:hover .itemGroup-title {
      text-decoration: underline;
    }

    &:hover .multiselectItem-action {
      border-color: $c-primary;
    }

    .title {
      position: relative;
      line-height: 24px;
    }
  }

  .item-group {
    padding: 0px;
  }

  .item {
    transition: all 0ms;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
    box-shadow: none;

    &:before {
      display: none;
    }

    &.relationship-hover,
    &.highlight-hover:hover {
      box-shadow: inset 0 0 0 1px $c-grey-light;
    }

    &.relationship-active {
      box-shadow:
        inset 0 0 0 1px $c-primary,
        inset 0 0 0 4px transparentize($c-primary, 0.7);
    }
  }

  @media (min-width: 1024px) {
    .item-metadata dt {
      @include tooltip('top-left');
    }
  }

  .search-box {
    width: calc(100% - 30px);
    max-width: 100%;
    margin: 15px 15px 30px 15px;
    z-index: 1;

    .form-control {
      font-size: $f-size;
    }
  }
}

.sidepanel-body {
  position: relative;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: $f-size-sm;
  margin-bottom: $FooterHeight;
  padding-bottom: $FooterHeight;

  @media (min-width: 1024px) {
    padding-bottom: 0;
  }

  &.without-footer {
    margin-bottom: 0;
  }

  &.footer-extra-row {
    margin-bottom: calc(#{$FooterHeight} * 2 + 2px);
  }

  // & > *:last-child {
  //   // firefox hack (related issue #378)
  //   padding-bottom: $header-height * 3;
  // }
  .sidepanel-relationships {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: $f-size-lg;

    .sticky {
      padding-top: 46px;

      .sidepanel-relationship {
        .sidepanel-relationship-left-label {
          position: fixed;
          top: 0;
          z-index: 1;
          width: 100%;
        }
      }
    }

    .sidepanel-relationship {
      display: flex;
      flex-direction: column;

      .sidepanel-relationship-left-label {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px;
        gap: 9px;
        background: #d7d7d7;
        border: 1px solid #d7d7d7;
      }

      .sidepanel-relationship-right {
        padding: 7px 10px 7px 10px;

        .sidepanel-relationship-collapsible {
          .header {
            cursor: pointer;
            font-weight: bold;
            padding: 6px 12px;
            gap: 12px;
            background: #eeeeee;
            border: 1px solid #d7d7dc;
            margin-bottom: 7px;

            .header-info {
              float: right;
            }

            .header-icon {
              margin-right: 6px;
              font-size: large;
            }
          }

          .content {
            .sidepanel-relationship-right-entity {
              cursor: pointer;
              height: 100%;
              margin-bottom: 7px;
              border: 1px solid #d7d7dc;
              box-sizing: border-box;

              gap: 9px;

              .item-document {
                z-index: -1;
                padding-bottom: 0;
                min-height: 0;

                .item-info {
                  padding: 5px 10px;
                }

                .item-metadata {
                  display: none;
                }

                .item-actions {
                  padding: 0;
                  height: auto;
                  padding: 0 10px 10px;
                  -webkit-align-items: center;
                  align-items: center;
                  position: relative;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidepanel-header {
  background: $c-sidebar;
  position: relative;
  min-height: $header-height;
  line-height: $header-height;
  border-bottom: 1px solid $c-grey-light;
  padding: 0 15px;

  .button-list > button {
    margin-right: 15px;
  }

  &.buttons-align-right {
    text-align: right;

    .button-list > button {
      margin-left: 15px;
      margin-right: unset;
    }

    .closeSidepanel {
      right: unset;
      left: 0;
    }
  }

  &.no-border {
    border-bottom: 0;
  }

  .item-info {
    padding: 0 0 30px;
  }

  .nav-tabs {
    position: absolute;
    left: 0;
    bottom: 0;
    right: $header-height;
  }

  .fa {
    font-size: $f-size-lg;
  }

  .entity-sidepanel-tab-link {
    width: 100%;
    height: 100%;
    display: block;
    color: grey;
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .selected {
    color: $c-primary;
  }
}

.sidepanel-title {
  position: relative;
  padding: 0 15px;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.6;
  line-height: 50px;
  height: 50px;
  border-bottom: 1px solid #d7d7dc;
  display: flex;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .filter-buttons {
    .clear-button {
      text-decoration: none;
      letter-spacing: normal;
      font-weight: normal;
      display: inline;
      text-transform: capitalize;
      border: 1px solid rgb(204, 204, 204);
      padding: 3px;
      cursor: pointer;
      border-radius: 2px;
    }

    .push-left {
      margin-right: 25px;
    }

    @media (min-width: 1030px) {
      .remove-margin {
        margin-right: 0px;
      }
    }
  }
}

.item.readOnly {
  cursor: default;

  &:hover {
    cursor: default;
  }
}

.form-group .form-title-actions {
  position: relative;

  .delete-selection {
    position: absolute;
    padding: 0;
  }

  .icon-selector {
    ul.search__filter {
      padding-top: 6px;
    }
  }
}

.add-thesauriValue-modal {
  .error {
    color: $c-type-1;
    font-size: 0.9em;
  }

  .thesauri-group {
    margin-bottom: 10px;
  }

  .cancel-button {
    background-color: #eceff1;
    border-color: #cfd8dc;

    &:hover {
      border-color: #3655ba;
      color: #3655ba;
    }
  }
}
