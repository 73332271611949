$theme: dark;
$hero: dark;
$c-theme: #60748b;
:root {
  --c-bg-menu-mobile: #eceff1;
  --c-text-menu-mobile: #444444;
  --c-menu-highlight: #b0bec5;
  --c-bg-active-link: #cfd8dc;
  --c-text-active-link: #202426;
}

$c-grey: #aeaeb8;
$c-white: lighten($c-grey, 100%);
$c-grey-lighten: lighten($c-grey, 25%);
$c-grey-light: lighten($c-grey, 15%);
$c-grey-dark: darken($c-grey, 25%);
$c-active-feature: orange;
$c-black: #444444;
$c-type-0: #c03b22;
$c-type-1: #d9534f;
$c-type-2: #e91e63;
$c-type-3: #a03ab1;
$c-type-4: #6f46b8;
$c-type-5: #3f51b5;
$c-type-6: #2196f3;
$c-type-7: #37bdcf;
$c-type-8: #359990;
$c-type-9: #5cb85c;
$c-type-10: #8bc34a;
$c-type-11: #cddc39;
$c-type-12: #ccbc2f;
$c-type-13: #f0ad4e;
$c-type-14: #ec9920;
$c-type-15: #e46841;
$c-type-16: #795548;
$c-type-17: #9e9e9e;
$c-type-18: #607d8b;
$c-type-19: #449d44;

$c-background: #fcfcfc;

$c-primary: #2b56c1;
$c-primary-lighten: lighten($c-primary, 50%);
$c-primary-light: lighten($c-primary, 40%);
$c-primary-dark: darken($c-primary, 10%);
$c-info: $c-type-7;
$c-info-light: lighten($c-info, 40%);
$c-info-dark: darken($c-info, 10%);
$c-success: $c-type-9;
$c-success-light: lighten($c-type-10, 30%);
$c-success-dark: darken($c-type-10, 15%);
$c-danger: $c-type-1;
$c-danger-light: lighten($c-type-1, 30%);
$c-danger-dark: darken($c-type-1, 10%);
$c-warning: $c-type-13;
$c-warning-light: lighten($c-type-14, 30%);
$c-warning-dark: darken($c-type-14, 10%);

$c-shadow: transparentize($c-black, 0.9);
$c-shadow-dark: transparentize($c-black, 0.8);
$c-shadow-darker: transparentize($c-black, 0.5);

$c-card-highlight: #dee7ff4d;
$c-language-highlight: #cff0cf;

$c-filter-film: #c2c2c2;

$c-gray-900: #101828;
$c-gray-600: #475467;
